.container {
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 500;
    font-size: 1.25rem;
    line-height: 1.6;
    letter-spacing: 0.0075em;
    color: #808080;
}
.container h2 {
    color: #000;
}
.container h3 {
    width: 100%;
}
.container a {
    color: #33004A
}
p.protect {
    width: 100%;
}